<!--  -->
<template>
  
  <div class="bg">
    <!-- 顶部搜索 -->
     
    <van-sticky style="background:white;">
       
    <div style="display:flex;justify-content:space-around;align-items: center;background: #ffffff;height: 12vw;">
      <div @click="$router.go(-1)"><img src="../assets/icon/leftQuit.png" alt="" style="width:20px;height:20px;margin-right: 3vw;"></div>
        <div style="width:90%;height: 9vw; border-radius: 4.5vw; display: flex; align-items: center; background: #f8f8f8;color: black;">
            <img src="../assets/icon/search_2.png" alt="" style="width:20px;height:20px;margin-left:4vw;" />
            <input type="text" placeholder="输入商品名称"  v-model="value" style="margin-left:2vw;border: 0;background-color:#f8f8f8;width:80%;height: 8vw;">
            <div style="margin-left:3vw">
            <div @click="showPopup(value)" style="width:18vw;height: 8.5vw;border-radius: 0 4.5vw 4.5vw 0;display: flex;justify-content: center;align-items: center;">搜索</div>
          
            <van-action-sheet v-model="show" position="bottom"   cancel-text="取消" close-on-click-action>   
                  <ul v-for="(item,index) in goodsList" :key="index" style="font-size:5vw;padding:3vw">
                  
                   <li @click="onSelect(item)" style="border-bottom:1px solid #E7E8E8">
                    <!-- <el-tooltip placement="top">
                  <div slot="content">{{item.title}}</div>
                  <div  style=" overflow:hidden; white-space: nowrap;width: 50vw;"  ></div>
                   </el-tooltip> -->
                   <span >{{index+1}}.</span>
                   {{item.title}}
                  </li>
                  </ul>
            </van-action-sheet>
        </div>
        </div>
    </div>

   </van-sticky>

   
    <!-- tab切换兰 -->
    <div v-if="informatiobShow">
        <van-tabs v-model="activeName" color="#04A9F3" title-active-color="#04A9F3" >
         <van-tab title="商品详情" name="a">
          <div style="border-top:1px solid #E7E8E8;margin-top: 2vw;"></div>
            <div style="width:99.5%;">
                
                <!-- <div  v-for="(item,index) in actionList " :key='index'> 
                 <div>{{item.name}}</div>
                 <div>{{item.id}}</div>
                </div> -->
                <div style="display:flex;">
                  <div class="left" style="width: 33%;background:#FFFFFF">
                   <ul style="font-size:4vw;font-weight:900">
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">货号</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">贯标码</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">条码</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">通用名</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">规格</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">产地</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">ABC分类</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">国药准字</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">单位</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">服用方法</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">服用频次</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">服用剂量</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">用量单位</li>
                    <li style="border-bottom:1px solid #E7E8E8;border-right:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center;">执行天数</li>
                   </ul>
                  </div>

                  <div class="right" style="width:67%;background:#FFFFFF;">
                    <ul  style="font-size:3.5vw;font-weight:900" >
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.货号}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.贯标码}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.条码}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.通用名}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.规格}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.产地}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.ABC分类}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.国药准字}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.单位}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.服用方法}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.服用频次}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.服用剂量}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.用量单位}}</li>
                    <li style="border-bottom:1px solid #E7E8E8;height:9vw;display: flex;align-items: center;justify-content: center; ">{{itemDetailsList.执行天数}}</li>
                    

                    
                   </ul>
                  </div>
                </div>
            </div>
         </van-tab>

         <van-tab title="图文详情" name="b">
            <div style="margin-left:5vw;margin-top:2vw;  width: 90vw;">
             <div class="whitePicture" style="background:#ffffff;width: 90vw; height: 50vw;border-radius: 3vw;margin-top: 1.5vw;" v-for="(item,index) in pictureDetailsList" :key="index">
             <img :src="item" alt="" style="width: 90vw; height: 50vw;border-radius: 3vw;">
            </div>
          
            </div>
         </van-tab>

         <van-tab title="商品库存" name="c">
            <div style="margin-left:3vw;margin-top:2vw; width: 90vw;">
              
              <div style="background: #FFFFFF;width:90vw;border-radius: 1vw;padding:2vw;">
                <!-- 蓝色 -->
                <div style="background:blue;width:88vw;height: 8vw;background:repeating-radial-gradient(#4BC4FA  , #04A9F3 );border-radius: 4vw;margin-left: 1vw;">
                 <ul style="display:flex;justify-content:space-around;color: #FFFFFF;font-size: 3vw;">
                  <li style="margin-top: 1.5vw;">品名</li>
                  <li style="margin-top: 1.5vw;">批号</li>
                  <li style="margin-top: 1.5vw;">效期</li>
                  <li style="margin-top: 1.5vw;">数量</li>
                 </ul>
                </div>
                <!-- 商品库存 -->
               <ul style="display:flex;justify-content:space-around;width: 88vw;align-items: center;font-size: 3.5vw;margin-top: 2vw;border-bottom: 1px solid #E7E8E8;padding: 1vw; " v-for="(item,index) in itemSaveList" :key="index">
                 <li style="width:20vw;">
                
                  <el-tooltip placement="top">
                  <div slot="content">{{item.品名}}</div>
                  <div  style=" overflow:hidden; white-space: nowrap;width:14vw;"  >{{item.品名}}</div>
                   </el-tooltip>
                
                </li>
                 <li style="margin-left:-7vw;">{{item.批号}}</li>
                 <li>{{item.有效期}}</li>
                 <li>{{item.数量}}</li>
               </ul>
              </div>
            
            
            </div>
         </van-tab>
       </van-tabs>

    </div>
    </div>
</template>

<script>
export default {
    
  data () {
    return {
        itemDetailsList:[],//商品详情
        pictureDetailsList:[],//图文详情
        itemSaveList:[],//商品库存
        goodsList:[],
        value:"",//输入框绑定value
        activeName: 'a',//tab切换
        show: false,//底部弹窗
        informatiobShow:false,
    
    }
  },

  components: {},

  computed: {
   
  },

  methods: {
  
    
    handleClick(tab, event) {
        console.log(tab, event);
      },
      showPopup(value) {
      if(value==""){
        this.$toast({ message:"请输入商品名或货号", duration:1500 });
        return false
      }
      this.user_info = JSON.parse(localStorage.getItem("user_info"));
      console.log(this.user_info.shopId,"token")
      console.log(value,"value")
      this.$axios.get(this.$api.goodsStatisticsList,{
        params: {
          keyWord:value,
          shopId:this.user_info.shopId,
          },
      } )
        .then((res) => {
          console.log(res, "商品名称");
         this.goodsList=res.data.resultList
         console.log(this.goodsList,"this.goodsList")
        });
      this.show = true;
      this.informatiobShow=false
    },
    onSelect(item) {
      this.show = false;
      this.value=""
      console.log(item.code,"aaaa")
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
    
    // //   Toast(item.name);
    this.informatiobShow=true
    // console.log(item.code,"底部弹出来选中的")
    this.user_info = JSON.parse(localStorage.getItem("user_info"));
    this.$axios.get(this.$api.goodsDetailList,{
        params: {
          code:item.code,
          shopId:this.user_info.shopId,
          },
      } )
        .then((res) => {
          console.log(res, "商品详情");
          // 商品详情
          this.itemDetailsList=res.data.resultList.goodsDetail
          console.log(this.itemDetailsList,"this.itemDetailsList")
          // 图文详情
          this.pictureDetailsList=res.data.resultList.imageDetail
          console.log(this.pictureDetailsList,"this.pictureDetailsList")
          //商品库存
          this.itemSaveList=res.data.resultList.inventoryDetail
          console.log(this.itemSaveList,"dddddd")
          
        });
          
    },
  }
}

</script>
<style>
.bg{
    height:200vh;
    width:100vw;
    background:rgba(64, 192, 249, 0.05)
  
}
.searchBox{
    width: 100%;
    padding: 30px 30px 12px 70px;
    box-sizing: border-box;
    display: flex;
    background: #fff;
    align-items: center;
}
.title_div {
  height: 6vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  line-height:6vh;
  color: #ffffff;
  text-align: center;
  font-size: 4.6vw;
}
.van-tabs--line .van-tabs__wrap {
  background: grey;
}
/* .whitePicture{
  
} */
</style>